/* global makeExternalPayment */
/* do not delete the above @fanitepay */

import React, { useEffect, useState } from "react";
import axios from "axios";

function Pay() {
  const [payIn, setPayIn] = useState({});
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get("payref");

        if (!code) {
          // Redirect to "fanitepay" or any other URL
          window.location.href = "https://fanitepay.com";
          return;
        }
        const response = await axios.get(`https://paylite.fanitehub.com/api/paylinks?payref=${code}`);
        setPayIn(response.data[0]);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://sdk.fanitepay.com/assets/fanite-live-sdk.js';
    script.async = true;

    script.onload = () => {
      // The script has loaded, you can now use the functions from the SDK
      //console.log('Fanite Live SDK has loaded.');
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const createWaveCheckoutPayload = () => {

    return {
      status: true,
      message: "Charge initiated",
      payref: `fnt-p-${Date.now()}`,
      data: {
        id: Date.now(),
        type: payIn.type,
        amount: payIn.amount,
        status: "initiated",
        message: payIn.name,
        currency: "UGX",
        customer: {
          email: email,
          phone_number: '',
          name: name,
          address: '',
        },
        metadata: {
          marchantID: payIn.merchantId,
          marchantName: payIn.amount,
          marchantEmail: "no-reply@example.com"
        },
        description: payIn.amount,
        logo: 'https://res.cloudinary.com/dr6nn6thq/image/upload/v1703854661/192x192_dwjxya.png',
        redirect: "https://www.fanitepay.com",
        settlementDestination: "wallet",
        items: "null",
      },
    };
  };

  const handlePaymentClick = () => {
    const waveCheckoutPayload = createWaveCheckoutPayload();
     // @ts-ignore
    makeExternalPayment(waveCheckoutPayload);
  };

  return (
    <>
      <div >
        <title>FanitePay | Infinite Payment Solutions</title>
        <link rel="stylesheet" href="style.css" />
        </div>
        <div data-server-rendered="true" id="__nuxt">
          <div id="__layout">
            <div data-fetch-key={0}>
              <div data-fetch-key="NuxtLayoutVisitorV2:0" className="layout-container ssi-layout">
                <div className="layout">
                  <main id="main" tabIndex={-1}>
                      <div id="main" data-fetch-key="homepage:0" className="hp-rebrand vs-bg-white">
                        {/**/}

                        
                        <div>
                         <section data-qa-section="delivery-models-client" className="container rebrand-delivery-models" data-v-881f17c8>
                            <div className="bg-delivery-model" data-v-881f17c8 style={{ backgroundImage: `url(${payIn.linkImage || 'https://res.cloudinary.com/dr6nn6thq/image/upload/v1704712188/fntp-brand_qxwzhe.png'})` }}>
                              <div className="air3-grid-container" data-v-881f17c8>
                                <div className="span-md-7 span-lg-5" data-v-881f17c8>
                                  
                                  <div className="form" data-qa="overview" data-v-881f17c8>
                                    <h3>{payIn.name}</h3><br/>
                                    <input  type="text" disabled value={payIn.amount} style={{ alignContent: "center"}} />
                                    <input  type="text"  
                                    style={{ alignContent: "center"}} 
                                    placeholder="Full Names"
                                    onChange={(e) => setName(e.target.value)}
                                    />
                                    <input  type="text"  
                                    style={{ alignContent: "center"}} 
                                    placeholder="Email"
                                    onChange={(e) => setEmail(e.target.value)}/>
                                    <button onClick={handlePaymentClick}>Pay Now</button>
                                  </div>
                                </div>
                              </div>
                              <div className="delivery-model-card-container" data-v-881f17c8>
                                <div data-qa="delivery-model-card-post-job" className="span-md-4 delivery-model-card" data-v-881f17c8>
                                  <a href="/utils" data-qa="card-link" className="up-n-link air3-btn air3-btn-primary air3-btn-block deliver-models-card-link" data-v-881f17c8>
                                    <span data-qa="model-title" className="model-title d-block" data-v-881f17c8>
                                    {payIn.name}
                                    </span><br/>
                                    <span className="mb-0 model-name" data-v-881f17c8>
                                      <span data-qa="model-name" data-v-881f17c8>{payIn.description}</span>
                                       </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <br />
                                      <span data-qa="model-name" data-v-881f17c8>For inquiries, email us at: <a href="mailto:info@fanitepay.com">info@fanitepay.com</a></span>
                          </section>
                         
                          
                        </div>
                        </div>
                        </main>
                </div>
                
              </div>
            </div>
          </div>
      </div>

    </>
  );
}

export default Pay;
