import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Verify from './views/verify';
import reportWebVitals from './reportWebVitals';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Function to render the appropriate component based on the URL
function renderComponent() {
  const path = window.location.pathname;
  
  if (path === '/verify') {
    return <Verify />;
  } else {
    return <App />;
  }
}

ReactDOM.render(
  <React.StrictMode>
     <ToastContainer
      position="bottom-center"
      autoClose={500}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
    />
    {renderComponent()} {/* Render the appropriate component based on the URL */}
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();

